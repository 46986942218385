import React from "react"
import { useLocation } from "@reach/router"
import {
    Box,
    Button,
    Flex,
    Heading,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Switch,
    useColorMode,
    VStack,
} from "@chakra-ui/react"
import { Link } from "gatsby"
import { FaGripLines } from "react-icons/fa"

const Header = () => {
    const { colorMode, toggleColorMode } = useColorMode()

    return (
        <Flex
            w="100%"
            justifyContent="space-between"
            alignItems="center"
            px={6}
            boxShadow="0 0 10px 2px rgba(255, 0, 128, 0.3)"
            background={colorMode === "light" ? "gray.600" : "gray.800"}
            mb={4}
        >
            <Flex w="100%" alignItems="center" justify="space-between">
                <Link to="/">
                    <Heading
                        fontSize={{ base: "md", md: "lg" }}
                        py={8}
                        color="white"
                    >
                        Masterlock App🏈
                    </Heading>
                </Link>
                <Flex gridGap={2} display={{ base: "none", md: "flex" }}>
                    <HeaderButton path={"/nfl"}>NFL</HeaderButton>
                    <HeaderButton path={"/cfb"}>College Football</HeaderButton>
                    <HeaderButton path={"/cbb"}>
                        College Basketball
                    </HeaderButton>
                    <Flex>
                        <Switch
                            colorScheme="brand"
                            mr={2}
                            onChange={() => toggleColorMode()}
                            isChecked={colorMode === "dark"}
                        />
                    </Flex>
                </Flex>
                <Popover>
                    <PopoverTrigger>
                        <Button
                            variant="ghost"
                            display={{ base: "initial", md: "none" }}
                        >
                            <FaGripLines size={30} color="white" />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                        <VStack>
                            <HeaderButton path={"/nfl"} textColor="#FF0080">
                                NFL
                            </HeaderButton>
                            <HeaderButton path={"/cfb"} textColor="#FF0080">
                                College Football
                            </HeaderButton>
                            <HeaderButton path={"/cbb"} textColor="#FF0080">
                                College Basketball
                            </HeaderButton>
                            <Flex alignItems="center">
                                <Switch
                                    p={4}
                                    colorScheme="brand"
                                    onChange={() => toggleColorMode()}
                                    isChecked={colorMode === "dark"}
                                />
                                Dark Mode
                            </Flex>
                        </VStack>
                    </PopoverContent>
                </Popover>
            </Flex>
        </Flex>
    )
}

export default Header

const HeaderButton: React.FC<{
    path: string
    textColor?: string
}> = ({ path, textColor, children }) => {
    const { pathname } = useLocation()

    return (
        <Link to={path}>
            <Button
                w="180px"
                variant="ghost"
                _hover={{}}
                textDecor={path === pathname ? "underline" : "none"}
                flexDir="column"
            >
                {children}
                <Box
                    mt={3}
                    bg="#FF0080"
                    w="50px"
                    h="2px"
                    transition="all 0.2s"
                />
            </Button>
        </Link>
    )
}
